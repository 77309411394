/*eslint-disable*/
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSortable } from 'react-sortablejs';
import styled from 'styled-components';
import QuestionItem from '../FormDesigner/Questions/QuestionItem';
import { sortableOptions } from './constant';
import { rearrange, removeSkipLogic, updateDependencyQuestion } from '../../redux/QuestionSlice';
import _ from 'lodash';
import GroupComponent from '../FormDesigner/GroupQuestion';
import InitialQuestion from '../FormDesigner/Questions/InitialQuestion';
import Header from '../Header/Header';
import { Toaster } from 'react-hot-toast';
import { ILogic, IQuestion, MainState } from '../../types/state';
import { IBlockWrapper } from '../../types/InputElement';
import { getAllQuestionsAtPath, getQuestionByPath } from '../../helpers/utils';

const StyledBlockWrapper = styled.div`
  border: 1px solid #e8e8e8;
  margin-bottom: 10px;
  cursor: move;
  border-radius: 10px;
`;

const SortableContainer: FC = (props) => {
  const dispatch = useDispatch();
  let questions = useSelector((state: MainState) => state.question.questions);
  const multiselect = useSelector((state: MainState) => state.question.multiselection);
  questions = _.cloneDeep(questions);

  const setQuestions = (list: any) => {
    dispatch(rearrange(list));
  };

  const onEnd = (event: any) => {
    const movedQuestion = questions[event.newIndex]; // Question before dragging
    const newQuestions = _.cloneDeep(questions); // Clone current questions
    const allPreviousQuestions = getAllQuestionsAtPath(movedQuestion.path, newQuestions);

    // // Check the logicList for the moved question
    if (movedQuestion.logicList && movedQuestion.logicList.length > 0) {
      movedQuestion.logicList.map((logic: ILogic) => {
        const isQuestionInPreviousList = allPreviousQuestions.some((question) => question.name === logic.questionName);

        // If not, dispatch an action to remove the logic item
        if (!isQuestionInPreviousList) {
          dispatch(removeSkipLogic({ path: movedQuestion.path, logicId: logic.id }));
        }
      });
    }
    if (movedQuestion.dependencyQuestion) {
      const isQuestionInPreviousList = allPreviousQuestions.some(
        (question) => question.name === movedQuestion.dependencyQuestion?.name,
      );

      // If not, dispatch an action to remove the logic item
      if (!isQuestionInPreviousList) {
        dispatch(
          updateDependencyQuestion({
            path: movedQuestion.path,
            newQuestion: null, // Remove dependencyQuestion
          }),
        );
      }
    }
  };

  return (
    <div style={{ padding: '2% 20%' }}>
      <Header />
      <br />
      {questions.length > 0 ? null : <InitialQuestion />}
      <ReactSortable
        list={questions}
        setList={setQuestions}
        //  disabled={isSettingsTabOpen}
        disabled={multiselect.onGoing}
        {...sortableOptions}
        onEnd={onEnd}
      >
        {questions.map((block: IQuestion, blockIndex: number) => (
          <BlockWrapper key={block.id} block={block} blockIndex={[blockIndex]} setBlocks={setQuestions} />
        ))}
      </ReactSortable>
      <Toaster
        position="top-right"
        reverseOrder={false}
        gutter={8}
        toastOptions={{
          duration: 4000,
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        }}
      />
    </div>
  );
};

function Container({ block, blockIndex, setBlocks }: any) {
  const questions = useSelector((state: MainState) => state.question.questions);

  const setList = (currentList: any) => {
    const tempList = _.cloneDeep(questions);
    if (tempList && tempList.length > 0) {
      const _blockIndex = [...blockIndex];
      const lastIndex = _blockIndex.pop();
      const lastArr = _blockIndex.reduce((arr, i) => {
        return arr[i]['questions'];
      }, tempList);
      lastArr[lastIndex]['questions'] = currentList;
      setBlocks(tempList);
    }
  };
  return (
    <ReactSortable key={block.id} list={block.questions} setList={setList} {...sortableOptions}>
      {block.questions &&
        block.questions.map((childBlock: IQuestion, index: number) => {
          return (
            <BlockWrapper
              key={childBlock.id}
              block={childBlock}
              blockIndex={[...blockIndex, index]}
              setBlocks={setBlocks}
            />
          );
        })}
    </ReactSortable>
  );
}

function BlockWrapper({ block, blockIndex, setBlocks }: IBlockWrapper) {
  if (!block) return null;
  if (block.type === 'Group Question' && block.questions.length > -1) {
    return (
      <GroupComponent groupItem={{ ...block, path: blockIndex.join(',') }}>
        <Container block={block} setBlocks={setBlocks} blockIndex={blockIndex} />
      </GroupComponent>
    );
  } else {
    return (
      <StyledBlockWrapper>
        <QuestionItem questionItem={{ ...block, path: blockIndex.join(',') }} />
      </StyledBlockWrapper>
    );
  }
}

export default SortableContainer;
