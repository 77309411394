import { CloseOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Box, IconButton, MenuItem } from '@material-ui/core';
import Grid from '@mui/material/Grid';
import { Select, Button, Input, Typography } from 'antd';
import React, { useEffect, useLayoutEffect } from 'react';
import ContactStyle from './Style';
import { conditions, skipLogicDefaultValue } from './utils';
import { fetchItems } from '../../../Service/Service';
import _ from 'lodash';
import Grow from '@mui/material/Grow';
import { ALLOWED_RELEVENCE_QUESTION_TYPES, MULTIPLE_SELECT, SELECT_ONE } from '../../QuesTypes/Constants';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { ILogic, IQuestion, MainState } from '../../../../types/state';
import {
  removeSkipLogic,
  updateQuestionDefaultLogic,
  updateSkipLogicCondition,
  updateSkipLogicName,
  updateSkipLogicValue,
} from '../../../../redux/QuestionSlice';
import { getAllQuestionsAtPath, getLocalizedText, getQuestionByPath } from '../../../../helpers/utils';
const { Option } = Select;

type ConditionSkipLogicPropsType = {
  question: IQuestion;
  setSkipLogicRender: (value: string) => void;
};

export default function ConditionSkipLogic({ question, setSkipLogicRender }: ConditionSkipLogicPropsType) {
  const classes = ContactStyle();

  // const [isSelect, setIsSelect] = React.useState(false);
  const [isConditionSelect, setIsCondtitionSelect] = React.useState(false);
  // const [options, setOptions] = React.useState<any[]>([]);
  const [options, setOptions] = React.useState<{ [key: string]: any[] }>({});
  const dispatch = useDispatch();

  const questions = useSelector((state: MainState) => state.question.questions);
  const defaultLanguage = useSelector((state: MainState) => state.language.defaultLanguage);
  const selectedQuestion: IQuestion = useSelector((state: MainState) => state.question.selectedQuestion);
  const currentQuestion = getQuestionByPath(questions, selectedQuestion.path);

  useLayoutEffect(() => {
    if (selectedQuestion.logicList.length === 0) {
      dispatch(updateQuestionDefaultLogic({ path: selectedQuestion.path, logic: skipLogicDefaultValue }));
    }
  }, []);

  // const getOptions = async (choice: any) => {
  //   const temp_options: any[] = [];

  //   if (choice.isDefault) {
  //     temp_options.push(choice.options);
  //   } else {
  //     const opt: any = await fetchItems(choice.id);

  //     opt.map((item: any) => {
  //       const cat_names = item.itemName;
  //       if (typeof cat_names !== 'string') {
  //         const cat_name = cat_names?.filter((cat: any) => cat.language.isDefault);
  //         if (cat_name[0]?.title !== '') item.option = cat_name[0]?.title;
  //       }
  //       item.value = item.itemValue;
  //     });
  //     temp_options.push(opt);
  //   }
  //   setOptions(temp_options[0]);

  //   return options;
  // };

  const getOptions = async (choice: any, logicId: string) => {
    const temp_options: any[] = [];

    if (choice.isDefault) {
      temp_options.push(choice.options);
    } else {
      const opt: any = await fetchItems(choice.id);

      opt.map((item: any) => {
        const cat_names = item.itemName;
        if (typeof cat_names !== 'string') {
          const cat_name = cat_names?.filter((cat: any) => cat.language.isDefault);
          if (cat_name[0]?.title !== '') item.option = cat_name[0]?.title;
        }
        item.value = item.itemValue;
      });
      temp_options.push(opt);
    }

    // Set options dynamically for the specific logicId
    setOptions((prevOptions) => ({
      ...prevOptions,
      [logicId]: temp_options[0], // Update options for the specific logic ID
    }));
  };

  return (
    <>
      <Box className={classes.closeOutlinedIcon}>
        <IconButton
          onClick={() => {
            setSkipLogicRender('');
            // onEvent('logicList', []);
          }}
        >
          <CloseOutlined className={classes.icon} />
        </IconButton>
      </Box>

      <Box className={classes.conditionSkipLogicText}>
        <p>Use the (+) and (-) buttons below to add and remove conditions</p>
        <p>
          This question will only be displayed <span></span> if the following conditions are true
        </p>
      </Box>
      {currentQuestion.logicList.map((logic: ILogic, index: number) => (
        <React.Fragment key={index}>
          <Box className={classes.conditionSkipLogicForm}>
            <IconButton
              className={classes.minusOutlinedIcon}
              onClick={() => dispatch(removeSkipLogic({ path: currentQuestion.path, logicId: logic.id }))}
            >
              <MinusOutlined className={classes.icon} />
            </IconButton>

            <Box margin={3}>
              <Grid item container rowSpacing={1} alignItems="center" xs={11}>
                <Grid item xs={12} md={12} lg={12}>
                  <Grid container alignItems="center">
                    <Grid item xs={4}>
                      <Typography> Select Question: </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      {' '}
                      <Select
                        value={logic.questionName}
                        onChange={(value: string) => {
                          // Find the selected question based on the value
                          const questionToSet = getAllQuestionsAtPath(
                            selectedQuestion.path,
                            questions,
                            ALLOWED_RELEVENCE_QUESTION_TYPES,
                          ).find((ques: IQuestion) => ques.name === value);

                          if (questionToSet) {
                            dispatch(
                              updateSkipLogicName({
                                path: currentQuestion.path,
                                logicId: logic.id,
                                questionName: value,
                                question: questionToSet, // Pass the selected question object
                              }),
                            );
                          }
                        }}
                        style={{ width: '100%' }}
                        allowClear
                      >
                        {getAllQuestionsAtPath(selectedQuestion.path, questions, ALLOWED_RELEVENCE_QUESTION_TYPES).map(
                          (ques: IQuestion, index: number) => (
                            <Option value={ques.name} key={index}>
                              {`${ques.name} (${ques.path})`}
                            </Option>
                          ),
                        )}
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>

                {logic.question && (
                  <Grow in={logic.question ? true : false} style={{ transitionDelay: logic.question ? '50ms' : '0ms' }}>
                    <Grid item xs={12} md={12} lg={12}>
                      <Grid container alignItems="center">
                        <Grid item xs={4}>
                          <Typography> Condition : </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Select
                            style={{ width: '100%' }}
                            onChange={(value) =>
                              dispatch(
                                updateSkipLogicCondition({
                                  path: currentQuestion.path,
                                  logicId: logic.id,
                                  condition: {
                                    name: value,
                                    value: value,
                                  },
                                }),
                              )
                            }
                            value={logic.condition?.value}
                            allowClear
                          >
                            {conditions.map((c: any, index: any) => (
                              <Select.Option value={c.value} key={index}>
                                {c.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grow>
                )}

                {logic.question &&
                  logic.condition &&
                  logic.condition.value !== 'was_not_answered' &&
                  logic.condition.value !== 'was_answered' && (
                    <Grow in={true} style={{ transitionDelay: '50ms' }}>
                      <Grid item xs={12} md={12} lg={12}>
                        <Grid container alignItems="center">
                          <Grid item xs={4}>
                            <Typography> Value : </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            {(logic.question?.type === SELECT_ONE || logic.question?.type === MULTIPLE_SELECT) && (
                              <Select
                                allowClear
                                style={{ width: '100%' }}
                                value={logic.value}
                                onChange={(value: string) =>
                                  dispatch(
                                    updateSkipLogicValue({
                                      path: currentQuestion.path,
                                      logicId: logic.id,
                                      value: value,
                                    }),
                                  )
                                }
                                onClick={() => {
                                  getOptions(logic.question?.choice, logic.id);
                                }}
                              >
                                {options[logic.id]?.map((logic: any, i: number) => (
                                  <Option value={logic.value} key={i}>
                                    {getLocalizedText(logic.option, defaultLanguage.code)}
                                  </Option>
                                ))}
                              </Select>
                            )}

                            {logic.question.type !== SELECT_ONE && logic.question.type !== MULTIPLE_SELECT && (
                              <Input
                                value={logic.value}
                                // onChange={(e) => setValue(logic, index, e.target.value)}
                                onChange={(e) =>
                                  dispatch(
                                    updateSkipLogicValue({
                                      path: currentQuestion.path,
                                      logicId: logic.id,
                                      value: e.target.value,
                                    }),
                                  )
                                }
                              />
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grow>
                  )}
              </Grid>
            </Box>
          </Box>
          <br />
        </React.Fragment>
      ))}

      <Grid container>
        <Grid container item justifyContent="right">
          <Button
            type="dashed"
            icon={<PlusOutlined />}
            size="large"
            onClick={() => {
              dispatch(
                updateQuestionDefaultLogic({
                  path: currentQuestion.path,
                  logic: skipLogicDefaultValue,
                }),
              );
            }}
          >
            Add Logic
          </Button>
        </Grid>

        {question.criteria && (
          <Grid container item justifyContent="center" xs={12}>
            <TextField
              style={{ width: '100%' }}
              sx={{ mx: 20 }}
              select
              id="standard-basic"
              label="Select a criteria"
              variant="standard"
              value={question.criteria}
              // onChange={(e: any) => {
              //   setCriteria(e.target.value);
              // }}
            >
              <MenuItem value="all">Question should match all of these criteria</MenuItem>
              <MenuItem value="any">Question should match any of these criteria</MenuItem>
            </TextField>
          </Grid>
        )}
      </Grid>
    </>
  );
}
export const labelCol = { span: 8, offset: 2 };
