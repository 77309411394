export const TEXT = 'text';
export const SELECT_ONE = 'select_one';
export const MULTIPLE_SELECT = 'select_multiple';
export const ACKNOWLEDGE = 'acknowledge';
export const AREA = 'area';
export const BARCODE = 'barcode';
export const NUMBER = 'integer';
export const FILE = 'file';
export const NOTE = 'note';
export const CALCULATE = 'calculate';
export const AUDIO = 'audio';
export const DATE_TIME = 'dateTime';
export const DATE = 'date';
export const DECIMAL = 'decimal';
export const PHOTO = 'image';
export const POINT = 'geopoint';
export const QUESTION_MATRIX = 'question_matrix';
export const RANGE = 'range';
export const RANKING = 'rank';
export const TIME = 'time';
export const VIDEO = 'video';
export const RATING = 'rating';
export const GROUP_QUESTION = 'Group Question';
export const LINE = 'line';

export const ALLOWED_RELEVENCE_QUESTION_TYPES = [
  'text',
  'select_one',
  'select_multiple',
  'integer',
  'dateTime',
  'date',
  'decimal',
  'time',
];

export const ALLOWED_DEPENDENCY_QUESTION_TYPES = ['select_one'];
